
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.itemWrapper {
    background: #ffffff;
    box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.1);
    padding: 25px;
    min-height: 240px;
}
// .item {
//     padding: 10px;
//     svg {
//         path {
//             fill: #52c9d1;
//         }
//     }
// }
